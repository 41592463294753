import { Button, Card, Col, Divider, Flex, Row, Typography } from 'antd';
import { CheckSquareOutlined } from '@ant-design/icons';
import { ALL_QUESTIONS, StepIndex } from '../../../constants/question';
import OhatCheckHeader from '../../components/check/OhatCheckHeader';
import OhatResultPanel from '../../components/check/OhatResultPanel';
import { createRef, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ohatScoreFindFirstAndLatest,
  patientFindOne,
  useOhatScoreCreate,
} from '../../../libs/ohat-api';
import useMobile from '../../../hooks/useMobile';
import { Patient } from '@models/patient';
import OhatCheckQuestionRow from '../../components/check/OhatCheckQuestionRow';
import { useGAEvent } from '../../../hooks/useGAEvent';
import { OhatScore } from '@models/ohatScore';
import { getInitAnswers } from '../../../utils/ohat-score-utils';
import { getNowISO } from '../../../utils/date';

export type Props = {
  isGuestMode: boolean;
};

export default function CheckScorePage(props: Props) {
  const { isGuestMode } = props;
  const navigate = useNavigate();
  const { sendClickEvent } = useGAEvent();
  const param = useParams();
  const { isMobile } = useMobile();
  const [patient, setPatient] = useState<Patient>();
  const [oldOhatScores, setOldOhatScores] = useState<OhatScore[]>([]);
  const [step, setStep] = useState<StepIndex>(0);
  const questionRefs = useRef(
    ALL_QUESTIONS.map(() => createRef<HTMLDivElement>()),
  );
  const [answers, setAnswers] = useState(getInitAnswers());
  const [isComplete, setIsComplete] = useState(false);
  const { mutateAsync: createOhatScore } = useOhatScoreCreate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [isComplete]);

  useEffect(() => {
    const fetchPatient = async () => {
      if (!isGuestMode && param.id) {
        const patientData = await patientFindOne(param.id);
        setPatient(patientData);
        const ohatScoreData = await ohatScoreFindFirstAndLatest(param.id, {
          baseDate: getNowISO(),
        });
        setOldOhatScores(ohatScoreData);
      }
    };
    fetchPatient();
  }, [isGuestMode, param.id]);

  const onBack = () => {
    if (step > 0) {
      setStep((step - 1) as StepIndex);
    } else {
      isGuestMode
        ? (window.location.href = `https://ohat.jp`)
        : navigate(`/patients/${param.id}`);
    }
  };

  const onNext = async () => {
    sendClickEvent('OHAT結果確認');
    setIsComplete(true);
    if (!isGuestMode) {
      await createOhatScore({
        data: {
          patient: { connect: { id: param.id! } },
          q1: answers.score[0],
          q2: answers.score[1],
          q3: answers.score[2],
          q4: answers.score[3],
          q5: answers.score[4],
          q6: answers.score[5],
          q7: answers.score[6],
          q8: answers.score[7],
        },
      });
    }
  };

  const onSelect = (step: StepIndex, answerIndex: number) => {
    const newState = Object.assign({}, answers);
    newState.score[step] = answerIndex;
    setAnswers(newState);

    if (step < ALL_QUESTIONS.length - 1) {
      const element = questionRefs.current[step + 1].current!;
      const offsetTop = element.getBoundingClientRect().top + window.scrollY;
      const offset = 60; // 例: ヘッダーの高さなど
      window.scrollTo({
        top: offsetTop - offset, // オフセットを引く
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Row gutter={[16, 8]} style={{ flex: 1, justifyContent: 'center' }}>
      <Col lg={14} md={18} sm={20} xs={24}>
        <Flex vertical gap={20}>
          {isGuestMode && (
            <img
              src={`${process.env.PUBLIC_URL}/logo.svg`}
              alt="Logo"
              height={50}
              style={{ marginTop: 20 }}
            />
          )}
          <Card
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {isComplete ? (
              <OhatResultPanel
                answers={answers}
                isGuestMode={isGuestMode}
                oldOhatScores={oldOhatScores}
                patient={patient}
              />
            ) : (
              <>
                <OhatCheckHeader onBack={onBack} />
                <Divider />
                <Typography.Text
                  style={{ fontSize: isMobile() ? 14 : 16 }}
                >{`Q１～Q８の各項目、いずれか一番近しいものを選択してください。`}</Typography.Text>

                {ALL_QUESTIONS.map((v, i) => {
                  const step = i as StepIndex;
                  return (
                    <OhatCheckQuestionRow
                      innerRef={questionRefs.current[step]}
                      onSelect={onSelect}
                      question={v}
                      answer={answers.score[step]}
                      step={step}
                      key={step}
                    />
                  );
                })}
                <Flex justify="center" align="center" vertical>
                  <Typography.Text
                    style={{ fontSize: isMobile() ? 9 : 12 }}
                  >{`※本評価シートは、東京科学大学 大学院 地域・福祉 口腔機能管理学分野 の許諾を得て使用しております`}</Typography.Text>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    icon={<CheckSquareOutlined />}
                    style={
                      isMobile()
                        ? { marginTop: 20, width: '100%' }
                        : { marginTop: 20, width: '50%' }
                    }
                    disabled={
                      !!Object.values(answers.score).find((v) => v === -1)
                    }
                    onClick={onNext}
                  >
                    結果を見る
                  </Button>
                </Flex>
              </>
            )}
          </Card>
        </Flex>
      </Col>
    </Row>
  );
}
